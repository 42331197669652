
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PROMO, Subscription } from '@/util/promo'
import {tariffPeriodTranslate, transcriptRuEn, tariffPeriodSimpleTranslate, periodPayments} from '@/util/helpers'
import KionButton from '@/components/Shared/Button.vue'
import { PaymentStep } from '@/views/main/components/Subscriptions.vue'
import { httpService, MtsBankWidgetConfig, RelatedSubscriptionsTextRequest, PaymentTypeEnum, PaymentSystemEnum } from '@/util/HttpAdapter'
import { DataLayer, gtm } from '@/util/GTMService'

declare global {
  interface Window {
    MTSPay: any;
  }
}

@Component({ components: { KionButton } })
export default class KionPaymentConfirm extends Vue {
  @Prop({ default: null }) subscription!: Subscription
  @Prop({ default: null }) paymentMethod!: PaymentStep.Card | PaymentStep.Phone
  translate = tariffPeriodTranslate
  translatePromoPeriod = tariffPeriodSimpleTranslate
  periodPayments = periodPayments
  processingTransaction = false
  processText = 'Производится оплата...'
  textWithPromoPeriod = 'Вы пытаетесь оформить покупку «' + this.subscription.name + '» за ' + this.subscription.discountPrice / 100 + ' руб. Промопериод - ' + this.translatePromoPeriod(this.subscription.tariffPeriod, this.subscription.promotion.timeToLiveValue) + ', далее стоимость - ' + this.subscription.price / 100 + ' ' + this.periodPayments(this.subscription.tariffPeriod) + '. Продолжить?';

  get commonDataLayer(): DataLayer {
    return {
      event: 'subscription_success',
      event_name: 'subscription_success',
      referer: '/code/subscriptions',
      price: this.subscription.discountPrice,
      subscription_name: PROMO[this.subscription.productId]?.name ?? '',
      subscription_id: this.subscription.productId,
      promocode: httpService.promoCode,
      deeplink: null,
      request_id: null,
      is_trial: (httpService.promoCode !== null && httpService.promoCode.length !== 0) ? 1 : 0,
    }
  }

  oldProductWhileConnected = [
    "a_lot_of_tv_premium_bvod_per_month_mts_ott_sdhd4k_b2c_v1_06_2020",
    "kion_for_premium_bvod_per_month_mts_ott_sdhd4k_b2c_v2_12_2023",
    "kion_for_premium_bvod_per_year_mts_ott_sdhd4k_b2c_v1_07_2022",
    "kionlight_premium_bvod_per_year_mts_ott_sdhd4k_b2c_v1_12_2021",
    "kion_for_premium_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2022"
  ]

  newProductWhileConnected = [
    "kion_base_premium_p_04_01_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2024",
    "kion_base_premium_p_04_01_12_01_bvod_per_year_mts_ott_sdhd4k_b2c_v1_07_2024",
    "kion_base_premium_p_04_01_01_02_bvod_per_month_mts_ott_sdhd4k_b2c_v2_07_2024",
    "kion_base_premium_p_04_01_03_01_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_07_2024",
    "kion_base_premium_p_04_01_06_01_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_07_2024"
  ]

  textCheckSun = {
    titleConfirmationUses1: '',
    descriptionConfirmationUses1: '',
    nextForConfirmationUses1: '',
    linkButton: '',
    linkCancelButton: '',
    titleConfirmationUses2: '',
    descriptionConfirmationUses2: '',
    nextForConfirmationUses2: '',
    descriptionBlocking: '',
  }

  textObj = {
    title: '',
    description: '',
    payButtonText: '',
    linkCancelButton: '',
    linkBlockingButton: '',
    descriptionBlocking: '',
  }

  onPreviousStep() {
    console.log('onPreviousStep')
    this.$emit('next', PaymentStep.Init)
  }

  mounted() {
    if (this.subscription && (this.subscription.discountPrice !== this.subscription.price && this.subscription.tariffPeriod)) {
      this.textObj.description = this.textWithPromoPeriod;
    }
	const data: RelatedSubscriptionsTextRequest = {
		productGid: this.subscription.productId,
		promoCode: httpService.promoCode,
		paymentType: {
			source: this.paymentMethod === PaymentStep.Card ? PaymentTypeEnum.CARD : PaymentTypeEnum.ACCOUNT,
			system: this.paymentMethod === PaymentStep.Card ? PaymentSystemEnum.MTS_MONEY : PaymentSystemEnum.FORIS_ONLINE
		},
	}

    httpService.checkSubscription(data).then(res => {

      //  Если есть блокирующие подписки
      if (res.data.blockingProductGids.length || res.data.cancelingProductGids.length) {
        this.textCheckSun.titleConfirmationUses2 = res.data.header;
        this.textCheckSun.descriptionConfirmationUses2 = res.data.text;
        this.textCheckSun.nextForConfirmationUses2 = res.data.button;

        gtm.pushEvent({
          event: 'subscribe_error',
          event_name: 'subscribe_error',
          price: this.subscription.price,
          subscription_name: this.subscription.name,
          subscription_id: this.subscription.productId,
          promocode: httpService.promoCode,
          error_message: `User already has blocking product: "${res.data.blockingProductGids.join(', ')}" or cancelling product: "${res.data.cancelingProductGids.join(', ')}"`,
          request_id: null
        })
      }
      if (KionPaymentConfirm.isStringIn2DArray(this.subscription.productId, this.newProductWhileConnected)) {
        httpService.getUsesSubscriptions().then(subs => {
          for (const sub of subs.data) {
            if (KionPaymentConfirm.isStringIn2DArray(sub.productId, this.oldProductWhileConnected) && sub.status === 'ACTIVATED') {
              this.textCheckSun.titleConfirmationUses1 = 'Подключение невозможно';
              this.textCheckSun.descriptionConfirmationUses1 = 'На вашем номере уже включены преимущества MTC Premium, в том числе Kion, Юрент, Строки, Музыка. '
                  + 'Вы можете сразу перейти к просмотру фильмов и сериалов. '
                  + 'При подключении Kion+Premiun cемейная группа может отключиться, если она была создана.';
              this.textCheckSun.linkButton = 'Перейти в KION';
              this.textCheckSun.linkCancelButton = 'Отключить Premium';
              this.textCheckSun.descriptionBlocking = 'Для подключения подписки «' + this.subscription.name + '» необходимо отключить подписку PREMIUM, при этом Семейная группа, если она была создана, отключится';
              this.setTextObjPay();
            }
          }
          if (!this.textObj.title) {
            this.setTextObjPay();
          }
        });
      } else {
        this.setTextObjPay();
      }
    });

  }

  moveTo(link: string) {
    window.location.assign(link);
  }

  setTextObjPay(): boolean {
    if (this.textCheckSun.titleConfirmationUses1) {
      this.textObj.title = this.textCheckSun.titleConfirmationUses1;
      this.textObj.description = this.textCheckSun.descriptionConfirmationUses1;
      this.textObj.payButtonText = this.textCheckSun.nextForConfirmationUses1;
      this.textObj.linkBlockingButton = this.textCheckSun.linkCancelButton;
      this.textObj.linkCancelButton = this.textCheckSun.linkButton;
      this.textObj.descriptionBlocking = this.textCheckSun.descriptionBlocking;
      this.textCheckSun.titleConfirmationUses1 = '';
      this.textCheckSun.descriptionConfirmationUses1 = '';
      this.textCheckSun.nextForConfirmationUses1 = '';
      this.textCheckSun.linkButton = '';
      this.textCheckSun.linkCancelButton = '';
      this.textCheckSun.descriptionBlocking = '';
      return false;
    } else if (this.textCheckSun.titleConfirmationUses2) {
      this.textObj.title = this.textCheckSun.titleConfirmationUses2;
      this.textObj.description = this.textCheckSun.descriptionConfirmationUses2;
      this.textObj.payButtonText = this.textCheckSun.nextForConfirmationUses2;
      this.textCheckSun.titleConfirmationUses2 = '';
      this.textCheckSun.descriptionConfirmationUses2 = '';
      this.textCheckSun.nextForConfirmationUses2 = '';
      return false;
    } else {
      this.textObj.title = 'Подтвердить покупку';
      if (this.subscription && (this.subscription.discountPrice !== this.subscription.price && this.subscription.tariffPeriod)) {
        this.textObj.description = this.textWithPromoPeriod;
      } else {
        this.textObj.description = 'Вы пытаетесь оформить подписку «' + this.subscription.name + '» за ' + this.subscription.discountPrice / 100 + ' ₽ ' + this.translate(this.subscription.tariffPeriod) + '. Продолжить?';
      }
      this.textObj.payButtonText = 'Продолжить';
      return true;
    }
  }

  pay() {
    if (this.setTextObjPay()) {
      this.processingTransaction = true
      if (this.paymentMethod === PaymentStep.Phone) {
        this.payWithPhone()
            .then(el => {
              if (el.status === 200) {
                this.$router.push({path: '/success', query: gtm.getAnalyticsQuery()}).then(() => {
                  gtm.pushEvent({
                    ...this.commonDataLayer,
                    payment_type: 'vps',
                  })
                })
              } else {
                gtm.pushEvent({
                  event: 'subscribe_error',
                  event_name: 'subscribe_error',
                  price: this.subscription.price,
                  subscription_name: this.subscription.name,
                  subscription_id: this.subscription.productId,
                  promocode: httpService.promoCode,
                  error_message: 'Request failed with status code' + el.status,
                  request_id: null
                })
                this.$router.push({path: 'error', query: gtm.getAnalyticsQuery()})
              }
            })
            .catch((e) => {
              window.sessionStorage.removeItem("request_id")
              gtm.pushEvent({
                event: 'subscribe_error',
                event_name: 'subscribe_error',
                price: this.subscription.price,
                subscription_name: this.subscription.name,
                subscription_id: this.subscription.productId,
                promocode: httpService.promoCode,
                error_message: e.message,
                request_id: null
              })
              this.$router.push({path: 'error', query: gtm.getAnalyticsQuery()})
            })
        return
      }
      if (this.paymentMethod === PaymentStep.Card) {
        this.payWithCard()
        return
      }
    }
  }

  payWithPhone() {
    this.processingTransaction = true
    return httpService.payWithPhone(this.subscription)
  }

  async payWithCard() {
    this.processingTransaction = true
    try {
      await this.injectWidget()

      const cfg = await httpService.getWidgetConfig(this.subscription, async (res) => {
        if (!res.isSuccess && res.eventName === 'failPayment') {
          await this.$router.push({path: '/error', query: gtm.getAnalyticsQuery()})
        } else {
          await this.checkSubscription(this.subscription)
              ? this.$router.push({path: '/success', query: gtm.getAnalyticsQuery()}).then(() => {
                gtm.pushEvent({
                  ...this.commonDataLayer,
                  payment_type: 'mts_money',
                })
              })
              .catch(e => {})
              : await this.$router.push({path: '/error', query: gtm.getAnalyticsQuery()})
        }
      })

      this.setupAndRunWidget(cfg)

    } catch (e) {
      await this.$router.push({path: '/error', query: gtm.getAnalyticsQuery()})
    }
  }

  private injectWidget(fallback = false) {
    return new Promise((resolve, reject) => {
      let widget = document.createElement('script')

      widget.onload = () => {
        console.info('Widget installed')
        resolve('')
      }

      widget.onerror = (err) => {
        if (!fallback) {
          document.head.removeChild(widget)
          console.warn('Widget download failed. Try to install from older version')
          this.injectWidget(true).then(resolve).catch(reject)
        }
        if (fallback) {
          console.error('Widget installation failed.')
          reject(err)
        }
      }

      document.head.appendChild(widget)
      widget.src = fallback ? (process.env.NODE_ENV === 'production' ? '/code' : '') + '/mts-bank-payment-widget.js' : 'https://pay.mts.ru/assets/js/web-sdk/v1/sdk.js'
    })
  }

  private async checkSubscription(s: Subscription): Promise<boolean> {
    this.processText = 'Производится активация промокода...'
    let timeout = 2000
    let subscriptionApplied = false
    for (let i = 0; i < 7; i++) {
      if (i === 0) {
        timeout = 2000
      }
      if (i === 1) {
        timeout = 3000
      }
      if (i > 1) {
        timeout = 5000
      }
      try {
        let res = await this.retry(s, timeout) as any[]
        if (res.length > 0) {
          subscriptionApplied = true
          break
        }
      } catch (e) {
        await this.$router.push({path: '/error', query: gtm.getAnalyticsQuery()})
      }
    }
    return subscriptionApplied
  }

  private async retry(s: Subscription, timer: number) {
    return new Promise((res, rej) => {
      setTimeout(() => {
        httpService.filterAppliedSubscriptions(s)
            .then(el => res(el.data))
            .catch(rej)
      }, timer)
    })
  }

  private setupAndRunWidget(config: MtsBankWidgetConfig) {
    if (window.MTSPay) {
      const widget = new window.MTSPay(config)
      widget.render('bank-payment-widget')
    }
  }

  private static isStringIn2DArray(target: string, array: string[]): boolean {
    for (const item of array) {
      if (item === target) {
        return true;
      }
    }
    return false;
  }

  goTo(name: 'Offer' | 'Rules') {
    let routeData = this.$router.resolve({ name })
    window.open(routeData.href, '_blank')
  }
}
